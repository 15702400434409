import { useRouter } from 'next/router';

import { BtnGroup } from '@playbooks/interface/button-groups';
import { AccentBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';

const AppLayout = ({ tailwind }) => {
	const router = useRouter();
	const { layout = '' } = router.query;

	// Methods
	const setRouterFilter = prop => {
		router.push({ query: { ...router.query, ...prop } }, undefined, { shallow: true });
	};

	// Render
	return (
		<BtnGroup divide='' width='w-full lg:w-auto' {...tailwind?.btnGroup}>
			<Span width='w-1/2'>
				<AccentBtn
					size='icon'
					borderRadius=''
					icon='grid-2'
					active={layout === ''}
					onClick={() => setRouterFilter({ layout: '' })}
					className='w-full lg:w-10'
					{...tailwind?.btn}
				/>
			</Span>
			<Span width='w-1/2'>
				<AccentBtn
					size='icon'
					borderRadius=''
					icon='list'
					active={layout === 'list'}
					onClick={() => setRouterFilter({ layout: 'list' })}
					className='w-full lg:w-10'
					{...tailwind?.btn}
				/>
			</Span>
		</BtnGroup>
	);
};

export { AppLayout };
