import {
	BountyDisplayCard,
	BountyListCard,
	BountyPreviewActionCard,
	BountyPreviewCard,
	BountySelectCard,
	BountySubmitCard,
} from 'components/bounty/bounty-card';
import { List } from 'molecules/lists';

const BountyList = ({ type = 'list', icon = 'sack-dollar', title = 'bounties', text = '', bounties, ...listProps }) => {
	const props = { type, icon, title, text, ...listProps };

	const renderItem = props => {
		switch (type) {
			case 'display':
				return <BountyDisplayCard bounty={props.item} {...props} />;

			case 'list':
				return <BountyListCard bounty={props.item} {...props} />;

			case 'preview':
				return <BountyPreviewCard bounty={props.item} {...props} />;

			case 'preview-action':
				return <BountyPreviewActionCard bounty={props.item} {...props} />;

			case 'select':
				return <BountySelectCard bounty={props.item} {...props} />;

			case 'submit':
				return <BountySubmitCard bounty={props.item} {...props} />;
		}
	};

	// Render
	return <List data={bounties} renderItem={renderItem} {...props} />;
};

export { BountyList };
