import { BtnGroup } from '@playbooks/interface/button-groups';
import { AccentBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { capitalize } from 'utils';

const BtnGroupFilter = ({ value, options = [], onSelect, tailwind }) => {
	// Render
	return (
		<BtnGroup divide='' width='min-w-xs w-full'>
			{options.map((option, i) => (
				<Span key={i} width='w-[1/4]' {...tailwind?.span}>
					<AccentBtn
						size='sm'
						borderRadius=''
						active={option === value}
						onClick={() => onSelect(option)}
						className='w-full'>
						{capitalize(option)}
					</AccentBtn>
				</Span>
			))}
		</BtnGroup>
	);
};
export { BtnGroupFilter };
