import { Fragment } from 'react';

import { AccentBtn } from '@playbooks/interface/buttons';
import { Small } from '@playbooks/interface/fonts';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { StarRatingWrapper } from 'components/wrappers/star-rating-wrapper';

const RatingFilter = ({ value, options = [], onSelect }) => {
	// Methods
	const isActive = option => {
		return value === option;
	};

	// Render
	return (
		<Fragment>
			{options.map((option, i) => (
				<AccentBtn
					key={i}
					active={isActive(option)}
					onClick={() => onSelect(option)}
					display='flex'
					span={{ flex: 'grow' }}
					className='w-full'>
					<Span display='flex-between' space='space-x-4'>
						<Span display='flex-start-bottom' space='space-x-4'>
							<StarRatingWrapper value={parseInt(option) || 0} dimensions='16px' spacing='2px' />
							<Small>or more</Small>
						</Span>
						{isActive(option) && <FadIcon icon='circle-check' color='green-400' fontSize='text-xl' />}
					</Span>
				</AccentBtn>
			))}
		</Fragment>
	);
};
export { RatingFilter };
